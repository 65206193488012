<template>
  <div class="container">
    <!-- <div>
      <span v-html="noticeList.content"></span>
    </div> -->
    <div class="grid_title">参合（参保）人员住院流程示意图</div>
    <van-steps direction="vertical" :active="-1">
      <van-step>
        <template #inactive-icon>
          <i class="icon-circle"></i>
        </template>
        <div class="card-item">
          <span class="card-title">一、参合（参保）人员门诊就诊：</span>
          <div class="card-line"></div>
          <div>
            <p class="card-desc">①新农合参合农民</p>
            <p class="card-desc">②省直参保职工</p>
          </div>
        </div>
      </van-step>
      <van-step>
        <template #inactive-icon>
          <i class="icon-circle"></i>
        </template>
        <div class="card-item">
          <span class="card-title">
            二、接诊医师对符合住院条件的参合（参保）人员进行身份和证件识别，对合作医疗证继续
            宁识别。
          </span>
        </div>
      </van-step>
      <van-step>
        <template #inactive-icon>
          <i class="icon-circle"></i>
        </template>
        <div class="card-item">
          <span class="card-title">
            三、住院收费处的收费人员对其合作医疗证、医保手册、医保卡、医疗本
          </span>
        </div>
      </van-step>
      <van-step>
        <template #inactive-icon>
          <i class="icon-circle"></i>
        </template>
        <div class="card-item">
          <span class="card-title">
            四、病区对其合作医疗证、医保手册、医保卡、医保本等有效证件核对无误后，收入病区，安排病床，在住院病人一览表中将各类参合（参保）
          </span>
        </div>
      </van-step>
      <van-step>
        <template #inactive-icon>
          <i class="icon-circle"></i>
        </template>
        <div class="card-item">
          <span class="card-title">
            五、病区要及时为符合出院条件的参合（参保）人员办理出院手续，严禁放宽住院标准，延长住院时间。参合（参保）人员到收费室办理出院结算
          </span>
        </div>
      </van-step>
    </van-steps>
    <p class="text-title"><span class="important">*</span>注：</p>
    <p class="text-title">一、目前我院就诊的人员有：</p>
    <p class="text-desc">1、新型农村合作医疗参合农民；</p>
    <p class="text-desc">2、省直参保职工；</p>
    <p class="text-desc">3、郑州市参保职工（居民）；</p>
    <p class="text-desc">
      4、新郑市参保职工（居民）。新农合、省医保、郑
      、新郑参保职工实行即时结报。其他保险和外地医保病人回当地医保中心报销。
    </p>
    <p class="text-title">
      二、医院工作人员在办理住院手续时，发现冒名顶替、提供假诊断依据等弄虚作假者，应扣留其证件，及时报医院合管办（医保办）处理。
    </p>
  </div>
</template>

<script>
export default {
  name: "medicalNotice",
  data() {
    return {
      noticeList: {},
    };
  },
  methods: {
    getData() {
      let postData = {
        parentId: this.$route.query.id,
      };
      this.$http.get("/api/content/infoByParentId", postData).then((res) => {
        this.noticeList = res.data.contents.find(
          (item) => item.title == "医保须知"
        );
      });
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style scoped>
.container {
  text-align: left;
  padding-bottom: 0.5rem;
}
.grid_title {
  font-size: 0.45rem;
  font-weight: bold;
  text-align: left;
  margin-left: 10px;
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
}

.grid_title:before {
  content: "";
  display: inline-block;
  width: 0.1rem;
  height: 0.5rem;
  margin: 0 10px;
  border-radius: 2px;
  background-color: #00d48b;
  position: relative;
  top: 3px;
}
.icon-circle {
  height: 15px;
  width: 15px;
  display: inline-block;
  border-radius: 50%;
  background: -webkit-radial-gradient(
    circle closest-side,
    #32ebab 50%,
    #adeed8 50%
  );
}
.card-item {
  padding: 0.3rem;
  margin-left: 0.3rem;
  -webkit-box-shadow: #e6e6e6 0px 0px 10px;
  -moz-box-shadow: #e6e6e6 0px 0px 10px;
  box-shadow: #e6e6e6 0px 0px 10px;
  border-radius: 5px;
  background-color: #fff;
}
.card-item:before {
  content: "";
  display: inline-block;
  width: 0;
  height: 0;
  position: absolute;
  left: -3px;
  width: 0;
  height: 0;
  border-width: 0 10px 10px;
  border-style: solid;
  border-color: transparent transparent #fff;
  transform: rotate(-90deg);
}
.card-title {
  font-size: 0.4rem;
  color: #3a3a3a;
  background-color: #fff;
}
.card-line {
  width: 98%;
  height: 1px;
  margin: 8px 0px;
  background-color: #e6e6e6;
}
.card-desc {
  line-height: 0.7rem;
  font-size: 0.45rem;
}
.container p {
  font-size: 0.4rem;
  padding: 0 0.5rem;
  line-height: 0.7rem;
}
/* .text-title {
  font-size: 0.45rem !important;
} */
.text-title .important {
  color: #fe9b51;
}
.text-desc {
  color: rgba(153, 153, 153, 1);
}
.text-desc:before {
  content: "";
  display: inline-block;
  height: 5px;
  width: 5px;
  border-radius: 50%;
  background-color: #fe9b51;
  margin-right: 0.2rem;
  position: relative;
  top: -5px;
}
/deep/.van-hairline:after {
  border: none;
}
/deep/.van-steps {
  background-color: transparent;
}
/* .content img{
  width: 99%;
}
.content{
  font-size: 0.4rem;
  width: 97%;
  margin: auto;
  margin-top: 0.2rem;
  text-align: left;
} */
</style>
